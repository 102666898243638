import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig.js";

// will be needed to update the subscriptionId in the state
/* const updateSubscriptionId = (subscriptionId) => ({
  type: "stripe/updateSubscriptionId",
  payload: subscriptionId,
}); */

// Async thunk for creating a customer and subscription
export const createCustomerAndSubscription = createAsyncThunk(
  "stripe/createCustomerAndSubscription",
  async ({ email, address, plan_id, currency }, thunkAPI, getState) => {
    try {
      const { csrfToken } = getState().auth;
      const response = await axiosInstance.post(
        "http://bossentrepreneur.com.au/bossstripe/create-customer",
        {
          email,
          address,
          plan_id,
          currency,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(
          response.data.error || "Failed to create customer and subscription"
        );
      }

      alert(
        "Customer and subscription created successfully by createCustomerAndSubscription!"
      );
      return response.data; // Contains subscriptionId and clientSecret
    } catch (error) {
      if (error.response && error.response.data) {
        // Server responded with an error
        alert("Failed to create customer and subscription");
        return thunkAPI.rejectWithValue(
          error.response.data.error || "Server error"
        );
      } else {
        // Network error or other issues
        alert("Failed to create customer and subscription");
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const checkPaymentStatus = createAsyncThunk(
  "stripe/checkPaymentStatus",
  async (_, thunkAPI, getState) => {
    try {
      const { csrfToken } = getState().auth;
      const response = await axiosInstance.get(
        `http://bossentrepreneur.com.au/bossstripe/check-payment-status`,
        {
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      alert("Payment status checked successfully");
      console.log("Check payment response status:", response.status);
      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(
          alert("Failed to check payment status", response.data.error),
          response.data.error || "Failed to check payment status"
        );
      }

      return response.data; // Returns true or false
    } catch (error) {
      if (error.response && error.response.data) {
        alert("Failed to check payment status", error.response.data.error);
        // Server responded with an error
        return thunkAPI.rejectWithValue(
          error.response.data.error || "Server error"
        );
      } else {
        // Network error or other issues
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk to fetch clientSecret from the backend
export const fetchClientSecret = createAsyncThunk(
  "stripe/fetchClientSecret",
  async (email, { rejectWithValue, getState }) => {
    try {
      const { csrfToken } = getState().auth;
      const response = await axiosInstance.post(
        `http://bossentrepreneur.com.au/bossstripe/create-setup-intent`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      alert("Client secret fetched successfully!");
      // Return the clientSecret from the response
      return response.data.clientSecret;
    } catch (error) {
      // Return the error message in case of failure
      return rejectWithValue(
        error.response?.data?.error || "Failed to fetch clientSecret"
      );
    }
  }
);

const stripeSlice = createSlice({
  name: "stripe",
  initialState: {
    subscriptionId: null,
    clientSecret: null,
    loading: false,
    error: null,
    isPaid: null,
  },
  reducers: {
    // Add any synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomerAndSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCustomerAndSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionId = action.payload.subscriptionId;
        state.clientSecret = action.payload.clientSecret;
        state.isPaid = action.payload.isPaid;
        console.log(
          "Customer and subscription fulfilled! isPaid:",
          state.isPaid
        );
      })
      .addCase(createCustomerAndSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
        console.log("Failed to create customer and subscription");
      })
      .addCase(checkPaymentStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkPaymentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.isPaid = action.payload.isPaid;
        console.log("Payment status checked successfully!", action.payload);
      })
      .addCase(checkPaymentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to check payment status";
        console.log("Failed to check payment status");
      })
      .addCase(fetchClientSecret.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClientSecret.fulfilled, (state, action) => {
        state.loading = false;
        state.clientSecret = action.payload;
      })
      .addCase(fetchClientSecret.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectStripeState = (state) => state.stripe;
// export const { updateSubscriptionId } = stripeSlice.actions;

export default stripeSlice.reducer;
