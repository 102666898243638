import React from 'react';

function Footer() {
  return (
    <footer style={footerStyle}>
      <div style={contentStyle}>
        <p>ABN: 15 776 628 622</p>
        <p>Email: <a href="mailto:dr.dbrown@icloud.com" style={linkStyle}>dr.dbrown@icloud.com</a></p>
        <p>&copy; 2024</p>
      </div>
    </footer>
  );
}

const footerStyle = {
  background: 'linear-gradient(to right, #1876D2, #000080)',
  textAlign: 'center',
  bottom: '0',
  width: '100%',
  borderTop: '1px solid #e7e7e7',
  position: 'relative' 
};

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  marginRight: 20,
  padding: '0',
  fontFamily: 'Verdana, sans-serif',
  fontSize: '14px',
  alignItems: 'flex-end',
  color: 'white'
};

const linkStyle = {
  color: 'white',
  textDecoration: 'none'
};


export default Footer;
