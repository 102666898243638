import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const NavigationDrawer = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          { text: "Action Plan", path: "/componentJ" },
          { text: "Business Plan", path: "/componentA" },
          { text: "Strategic Plan", path: "/componentB" },
          { text: "Culture Plan", path: "/componentC" },
          { text: "Future Plan", path: "/componentD" },
          { text: "Legal Plan", path: "/componentE" },
          { text: "Market Analysis", path: "/componentF" },
          { text: "Market Plan", path: "/componentG" },
          { text: "Competitors", path: "/componentH" },
          { text: "Customer Analysis", path: "/componentI" },
          { text: "Hiring Plan", path: "/componentL" },
          { text: "Products", path: "/componentM" },
          { text: "Risk Analysis", path: "/componentN" },
          { text: "Budget Plan", path: "/componentK" },
          { text: "Send Feedback", path: "/componentP" },
        ].map(({ text, path }) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              component={Link}
              to={path}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};

export default NavigationDrawer;
