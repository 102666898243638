import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavigationDrawer from "./NavigationDrawer.js";
import BusinessPlan from "./components/BusinessPlan.js";
import StrategicPlan from "./components/StrategicPlan.js";
import CulturePlan from "./components/CulturePlan.js";
import FuturePlan from "./components/FuturePlan.js";
import LegalPlan from "./components/LegalPlan.js";
import MarketAnalysis from "./components/MarketAnalysis.js";
import MarketPlan from "./components/MarketPlan.js";
import Competitors from "./components/Competitors.js";
import CustomerAnalysis from "./components/CustomerAnalysis.js";
import GanttChart from "./components/GanttChart.js";
import BudgetPlan from "./components/BudgetPlan.js";
import Hiring from "./components/Hiring.js";
import Products from "./components/Products.js";
import RiskAnalysis from "./components/RiskAnalysis.js";
import Feedback from "./components/Feedback.js";
import Welcome from "./Welcome.js";
import Login from "./Login.js";
import ResetPass from "./ResetPass.js";
import Register from "./Register.js";
import Settings from "./components/Settings.js";
import Checkout from "./components/Checkout.js";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { logout } from "./store/authSlice.js"; // Import the actions
import { clearAllData } from "./thunks/fetchBackendData.js";
import { fetchClientSecret, checkPaymentStatus } from "./store/stripeSlice.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51PfEUYRwRACGWZcExn42V3j72m5xHU8KU98oz3AtxzOmtW3BJiK3qzyCdceSHXPdoHZtHYqNdOYmDoeNMO1uG7Ns00oRvUhffc"
);

const AppContent = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [resetPassOpen, setResetPassOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isPaid = useSelector((state) => state.stripe.isPaid);
  const clientSecret = useSelector((state) => state.stripe.clientSecret);

  useEffect(() => {
    if (user && user.id) {
      dispatch(checkPaymentStatus());
      dispatch(fetchClientSecret(user.email));
      if (!isPaid) {
        setCheckoutOpen(true);
        console.log("User is not paid.");
      } else {
        console.log("User has Already Paid.");
      }
    }
  }, [user, isPaid]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLoginClick = () => {
    setLoginOpen(true);
  };

  const handleRegisterClick = () => {
    setRegisterOpen(true);
  };

  const handleLoginClose = async () => {
    setLoginOpen(false);
  };

  const handleRegisterClose = () => {
    setRegisterOpen(false);
  };

  const handleResetPassClose = () => {
    setResetPassOpen(false);
  };

  const handleLoginSuccess = () => {
    console.log("Inside handleLoginSuccess function");
    setLoggedIn(true);
    setLoginOpen(false);
  };

  const handleRegisterSuccess = () => {
    setRegisterOpen(false);
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    dispatch(logout());
    dispatch(clearAllData());
    handleMenuClose();
    navigate("/");
  };

  const handleCheckoutSuccess = async () => {
    try {
      if (isPaid) {
        setCheckoutOpen(false);
        alert("Payment plan successful!");
      } else {
        setLoggedIn(false);
        navigate("/");
      }
    } catch (error) {
      console.error("Error during payment:", error);
      alert("Error during payment. Please try again.");
    }
  };

  const handleCheckoutFailure = (errorMessage) => {
    setCheckoutOpen(false);
    alert(`Checkout failed: ${errorMessage}`);
    navigate("/");
  };

  return (
    <div className="App">
      <AppBar
        position="static"
        style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
      >
        <Toolbar>
          {loggedIn && <NavigationDrawer />}
          <Typography
            variant="h5"
            style={{
              marginLeft: 50,
              fontFamily: "Verdana",
              fontWeight: "bold",
            }}
          >
            BOSS Entrepreneur
          </Typography>
          {loggedIn ? (
            <>
              <Button
                color="inherit"
                onClick={handleMenuClick}
                style={{ marginLeft: "auto" }}
              >
                {user.name}
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem onClick={() => navigate("/settings")}>
                  Settings
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button
                color="inherit"
                style={{ marginLeft: "auto" }}
                onClick={handleLoginClick}
              >
                Login
              </Button>
              <Button color="inherit" onClick={handleRegisterClick}>
                Register
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Routes>
        {loggedIn ? (
          <>
            <Route path="/componentA" element={<BusinessPlan />} />
            <Route path="/componentB" element={<StrategicPlan />} />
            <Route path="/componentC" element={<CulturePlan />} />
            <Route path="/componentD" element={<FuturePlan />} />
            <Route path="/componentE" element={<LegalPlan />} />
            <Route path="/componentF" element={<MarketAnalysis />} />
            <Route path="/componentG" element={<MarketPlan />} />
            <Route path="/componentH" element={<Competitors />} />
            <Route path="/componentI" element={<CustomerAnalysis />} />
            <Route path="/componentJ" element={<GanttChart />} />
            <Route path="/componentK" element={<BudgetPlan />} />
            <Route path="/componentL" element={<Hiring />} />
            <Route path="/componentM" element={<Products />} />
            <Route path="/componentN" element={<RiskAnalysis />} />
            <Route path="/componentP" element={<Feedback />} />
            <Route
              path="/settings"
              element={<Settings onLogout={handleLogout} />}
            />
            <Route
              path="/login"
              element={
                <Login
                  open={loginOpen}
                  onClose={handleLoginClose}
                  onLoginSuccess={handleLoginSuccess}
                />
              }
            />
            <Route
              path="/register"
              element={
                <Register
                  open={registerOpen}
                  onClose={handleRegisterClose}
                  onRegisterSuccess={handleRegisterSuccess}
                />
              }
            />
            <Route
              path="/reset-password"
              element={
                <ResetPass
                  open={resetPassOpen}
                  onClose={handleResetPassClose}
                />
              }
            />
            {!isPaid && (
              <Route
                path="/checkout-form"
                element={
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    {" "}
                    {/* Wrap Checkout in Elements */}
                    <Checkout
                      open={checkoutOpen}
                      onClose={() => setCheckoutOpen(false)}
                      onCheckoutSuccess={handleCheckoutSuccess}
                      onCheckoutFailure={handleCheckoutFailure}
                    />
                  </Elements>
                }
              />
            )}
          </>
        ) : (
          <Route
            path="/"
            element={
              <Welcome
                onLoginClick={handleLoginClick}
                onRegisterClick={handleRegisterClick}
              />
            }
          />
        )}
      </Routes>
      {loginOpen && (
        <Login
          open={loginOpen}
          onClose={() => setLoginOpen(false)}
          onLoginSuccess={handleLoginSuccess} // Pass function reference correctly
        />
      )}
      {registerOpen && (
        <Register
          open={registerOpen}
          onClose={() => setRegisterOpen(false)}
          onRegisterSuccess={handleRegisterSuccess}
        />
      )}
      {resetPassOpen && (
        <ResetPass open={resetPassOpen} onClose={handleResetPassClose} />
      )}
      {loggedIn && !isPaid && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          {/* Wrap Checkout in Elements here as well */}
          <Checkout
            open={checkoutOpen}
            isPaid={isPaid}
            onClose={() => setCheckoutOpen(false)}
            onCheckoutSuccess={handleCheckoutSuccess}
            onCheckoutFailure={handleCheckoutFailure}
          />
        </Elements>
      )}
    </div>
  );
};

function App() {
  return <AppContent />;
}

export default App;
